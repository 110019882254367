var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"contract-draft-modal",attrs:{"name":"contract-draft-modal","transition":"fade","adaptive":true,"scrollable":true,"reset":true,"width":"60%","height":"80%"},on:{"closed":function($event){return _vm.$emit('updateComparisonData')},"before-open":_vm.beforeOpen}},[_c('div',{attrs:{"id":"modal-header"}},[_c('div',{staticClass:"w-75 d-flex"},[_c('p',[_vm._v("Պայմանագրի նախագիծ")])]),_c('button',{staticClass:"close position-absolute",staticStyle:{"right":"1.25rem","top":"25%"},attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.$modal.hide('contract-draft-modal')}}},[_c('svg',{attrs:{"width":"17","height":"16","viewBox":"0 0 17 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M16.5 1.64102L14.859 0L8.49997 6.359L2.14102 4.62582e-05L0.5 1.64107L6.85895 8.00002L0.500034 14.3589L2.14105 16L8.49997 9.64104L14.8589 16L16.5 14.359L10.141 8.00002L16.5 1.64102Z","fill":"#ABAEB8"}})])])]),_c('div',{attrs:{"id":"modal-body"}},[_c('vue-html2pdf',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],ref:"html2Pdf",attrs:{"show-layout":true,"float-layout":false,"enable-download":false,"paginate-elements-by-height":900,"filename":"Պայմանագրի նախագիծ","pdf-quality":2,"pdf-content-width":"auto","html-to-pdf-options":{
            margin: [5, 5, 0, 5],
            filename: 'Պայմանագրի նախագիծ',
            jsPDF: { format: 'a4', orientation: 'portrait' },
            html2canvas: {
                scrollX: 0,
                scrollY: 0
            }
        }},on:{"beforeDownload":function($event){return _vm.beforeDownload($event)}}},[_c('section',{attrs:{"slot":"pdf-content","contenteditable":"true"},domProps:{"innerHTML":_vm._s(_vm.content)},slot:"pdf-content"})])],1),_c('div',{attrs:{"id":"modal-footer"}},[_c('button',{staticClass:"btn btn-light mr-3",on:{"click":function($event){return _vm.$modal.hide('contract-draft-modal')}}},[_vm._v(" Փակել ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","aria-expanded":"false"},on:{"click":_vm.download}},[_c('span',{staticClass:"mr-2"},[_vm._v("Բեռնել")]),_c('font-awesome-icon',{attrs:{"icon":['fas', 'download']}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }